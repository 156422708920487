import styled from 'styled-components'

export const ModalCreateByExcelContainer = styled.div `
    width: 100%;

    .tep-mau {
        background-color: #20c997;
        border: none;
    }
    .tep-mau:hover {
        background-color: #10e4a5 !important;
        border: none;
    }
`