export const apiGetAllBlog = "Blog/GetBlogListForAdmin"
export const apiGetCategoryBLog = "BlogGroup/GetBlogGroupList"
export const apiDeleteBlog = "Blog/Delete"
export const apiUpdateBlog = "Blog/Update"
export const apiCreateBlog = "Blog/Create"
export const apiGetAllUser = "User/GetAllUser"
export const apiDeleteBlogCategory = "BlogGroup/Delete"
export const apiCreateBlogCategory = "BlogGroup/Create"
export const apiUpdateBlogCategory = "BlogGroup/Update"
export const apiGetAllContact = "Contact/GetContactList"
export const apiDeleteContact = "Contact/Delete"
export const apiGetDataDashboard = "Dashboard/GetDashboardAdmin"
export const apiBanAccount = "User/UpdateStatus"
export const apiGetAllTransaction = "WalletTransaction/GetWalletTransactionListForAdmin"
export const apiChangeStatusWithdraw = "WalletTransaction/UpdateStatus"
export const apiGetAddAdvertisement = "AdvertisementPricing/GetAllAdvertisementPricing"
export const apiCretaeAdvertisementPrice = "AdvertisementPricing/Create"
export const apiUpdateAdvertisementPrice = "AdvertisementPricing/Update"
export const apiDeleteAdvertisementPrice = "AdvertisementPricing/Delete"
export const apiGetAllBookingAds = "BookingAdvertisement/GetBookingAdvertisementForAdmin"
export const apiUpdateBookingAds = "BookingAdvertisement/UpdateStatus"
export const apiActiveBlog = "Blog/Active"
export const apiGetAllOrder = "Order/GetOrderListForAdmin"
export const apiGetListReport = "Report/GetReportList"
export const apiCreateCategoryRestaurant = "CategoryRestaurant/Create"
export const apiUpdateCategoryRestaurant = "CategoryRestaurant/Update"
export const apiGetPolicySystem = "PolicySystem/GetAllPolicies"
export const apiDeletePolicy = "PolicySystem/Delete"
export const apiCreatePolicy = "PolicySystem/Create"
export const apiUpdatePolicy = "PolicySystem/Update"
export const apiHandleReport = "Report/HandleReport"
export const apiHandleOrderReport = "Order/HandleReportOrder"
export const apiDeleteCategory = "CategoryRestaurant/Delete"

