import styled from "styled-components";

export const ChangePasswordContainer = styled.div`
	/* width: 100%; */
	padding: 20px 40px 0 40px;

	.title {
	}

	.form {
		margin-top: 30px;
		border-radius: 10px;
		background-color: #fff;

		.form-item {
		}

		.input {
			height: 50px;
		}

		.button {
			border: none;
			color: white;
			height: 48px;
			width: 170px;
			font-size: 16px;
		}
	}
`;
