export const apiLogin = "User/Login";
export const apiUpdateProfile = "Customer/UpdateProfile";
export const apiGetListWishList = "Wishlist/GetWishlistList";
export const apiAddWishList = "WishList/Create";
export const apiDeleteWishList = "Wishlist/Delete";
export const apigetCurrentUser = "User/GetProfile";
export const apiGetFeedback = "Feedback/GetFeedbacksHistory";
export const apiCreateFeedback = "Feedback/Create";
export const apiDeleteFeedback = "Feedback/Invisible";
export const apiGetListRestaurants =
	"CategoryRestaurant/GetAllCategoryRestaurants";
export const apiRegisterRestaurant = "User/RegisterRestaurant";
export const apiUpdateDesRestaurant = "Restaurant/UpdateDescription";
export const apiForgotPassword = "User/ForgotPassword";
export const apiVerifyOTP = "User/VerifyOTP";
export const apiResetPassword = "User/ResetPassword";
export const apiChangePassword = "User/ChangePassword";
export const apiGetMenu = "Menu/GetMenuListForCustomer";
export const apiGetTable = "RestaurantTables/GetAvailableTables";
export const apiCalTotalOrder = "Order/CalculateTotalAmountFreDiscount";
export const apiGetAllDiscount = "Discount/GetAvailableDiscount";
export const apiCreateOrder = "Order/Create";
export const apiGetOrderHistory = "Order/GetOrdeHistoryForCustomer";
export const apiViewOrderDetail = "Order/GetOrder";
export const apiPaidOrder = "Order/PaidOrder";
export const apiGetWalletInfo = "Wallet/GetWalletInfo";
export const apiAddOTP = "Wallet/AddOTP";
export const apiCreateOrUpdateBank = "Wallet/CreateOrUpdateBank";
export const apiDeposit = "WalletTransaction/Recharge";
export const apiCheckRecharge = "WalletTransaction/CheckRecharge";
export const apiRegisterCustomer = "User/RegisterCustomer";
export const apiUpdateResInfor = "Restaurant/UpdateRestaurantInfo";
export const apiCheckEmail = "User/CheckExistEmail";
export const apiGetTransactionHistory =
	"WalletTransaction/GetWalletTransactionList";
export const apiWithdraw = "WalletTransaction/Withdraw";
export const apiLoginGG = "User/LoginWithGoogle";
export const apiCheckPaymentOrder = "Order/CheckPayment";
export const apiCancelOrder = "Order/CancelOrder";
export const apiGetRestaurantDashboard = "Dashboard/GetDashboardRestaurant";
export const apiChangeMenus = "Order/ChangeMenus";
export const apiAddMenus = "Order/AddMenus";
export const apiGetAllOrderByRestaurant = "Order/GetOrderListForRestaurant";
export const apiUpdateOrderForConfirm = "Order/UpdateStatus";
export const apiCreateTableOrder = "Order/CreateOrderTable";
export const apiUpdateRestaurantProfile = "Restaurant/UpdateRestaurantInfo";
export const apiGetAllTableLazy = "TableBookingSchedule/GetTableBookingScheduleList";
export const apiGetAllTableScheduleList = "RestaurantTables/GetTableScheduleList";
export const apiCreateScheduleTable = "TableBookingSchedule/Create";
export const apiDeleteScheduleTable = "TableBookingSchedule/Delete";
export const apiGetAllImageRestaurant = "Image/GetImageList"
export const apiUpdatePolicy = "Restaurant/UpdateDiscountAndFee"
export const apiGetAllFeedbackByRestaurant = "Feedback/GetFeedbackListForRestaurant"
export const apiGetDiscountByRestaurant = "Discount/GetDiscountList"
export const apiUpdateActiveDiscount = "Discount/Active"
export const apiDeleteDiscount = "Discount/Invisible"
export const apiUpdateDiscount = "Discount/Update"
export const apiCreateDiscount = "Discount/Create"
export const apiUpdateScheduleTable = "TableBookingSchedule/Update"
export const apiGetAllRestaurantTable = "RestaurantTables/GetTableList"
export const apiUpdateTable = "RestaurantTables/Update"
export const apiDeleteTable = "RestaurantTables/Invisible"
export const apiGetAllRoom = "RestaurantRoom/GetRoomList"
export const apiCreateTable = "RestaurantTables/Create"
export const apiIsEnabledRoom = "RestaurantRoom/IsEnabledBooking"
export const apiInvisibleRoom = "RestaurantRoom/Invisible"
export const apiUpdateRoom = "RestaurantRoom/Update"
export const apiCreateRoom = "RestaurantRoom/Create"
export const apiGetAllMenu = "Menu/GetMenuListForRestaurant"
export const apiDeleteMenu = "Menu/Invisible"
export const apiUpdateMenu = "Menu/Update"
export const apiGetAllCategoryMenu = "CategoryMenu/GetAllCategoryMenus"
export const apiCreateMenu = "Menu/Create"
export const apiActiveMenu = "Menu/IsActive"
export const apiDeleteCategoryMenu = "CategoryMenu/Invisible"
export const apiCreateCategoryMenu = "CategoryMenu/Create"
export const apiUpdateCategoryMenu = "CategoryMenu/Update"
export const apiUploadFileMenu = "Menu/CreateByExcel"
export const apiSearchDataByMonth = "Dashboard/TaskBarSearchByMonthRestaurant"
export const apiSearchDataByDay = "Dashboard/TaskBarSearchByDayRestaurant"
export const apiGetAllBookingAds = "BookingAdvertisement/GetBookingAdvertisementForRestaurant"
export const apiPaymentBooking = "BookingAdvertisement/ChoosePaymentGatePaymentGateway"
export const apiCheckPaymentBooking = "BookingAdvertisement/CheckPayment"
export const apiCretaeAdsBooking = "BookingAdvertisement/Create"
export const apiCancelBookingAds = "BookingAdvertisement/UpdateStatus"
export const apiCreateTableByExcel = "RestaurantTables/CreateByExcel"
export const apiCreateRoomByExcel = "RestaurantRoom/CreateByExcel"
export const apiEnableBooking = "Restaurant/IsEnabledBooking"
export const apiGetAllNoti = "Notification/GetNotificationList?pageNumber"
export const apiReadNoti = "Notification/IsRead"
export const apiDeleteNoti = "Notification/Delete"
export const apiReadAllNoti = "Notification/IsReadAll"
export const apiDeleteAllNoti = "Notification/DeleteDeleteAll"
export const apiCreateChatBox = "ChatBox/Create"
export const apiGetChatBox = "ChatBox/GetChatBoxList"
export const apiGetChatList = "Chat/GetChatList"
export const apiCreateChat = "Chat/Create"
export const apiReport = 'Report/Create'
export const apiCheckExistChatBox = 'ChatBox/CheckExist'
export const apiReadChatBox = 'ChatBox/IsRead'
export const apiCreateReply = 'Feedback/CreateFeedbackReply'
export const apiViewFeedback = 'Feedback/GetFeedback'
export const apiReturnFee = 'Order/ReturnFeePercentApplyCustomerFee'
export const apiCreatePolicy = 'RestaurantPolicy/Create'
export const apiGetInActivePolicy = 'RestaurantPolicy/GetInActivePolicies'
export const apiChoosePolicy = 'RestaurantPolicy/ChoosePolicyToUse'
export const apiDeleteChatBox = 'ChatBox/Delete'
export const apiDeleteChat = 'Chat/Delete'



